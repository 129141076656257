import { Component } from "preact";
import { route } from "preact-router";
import { API_HOST } from "../constants";
import API from "../lib/api";

export default class Footer extends Component {
  componentDidMount() {
    this.api = new API(API_HOST);
  }

  async writeCode() {
    await this.api.post("/project/write");
    alert("Code written");
  }

  async restore() {
    await this.api.post("/project/restore");
    alert("Code written");
  }

  render() {
    let { versionsMenuOpen, versions, templatesMenuOpen, templates } =
      this.state;
    let hasVersions = versions && versions.length > 0;
    return (
      <footer className="border-t p-4 fixed left-0 bottom-0 w-full bg-white">
        <div className="container mx-auto flex justify-between items-center">
          <nav></nav>
          <nav className="relative">
            <button
              className="px-4 py-2 border border-black m-2 "
              onClick={e => this.restore()}
            >
              Restore
            </button>
            <button
              className="px-4 py-2 bg-black border border-black text-white m-2 "
              onClick={e => this.writeCode()}
            >
              Write code
            </button>
          </nav>
        </div>
      </footer>
    );
  }
}
