import { Component } from "preact";

import API from "../lib/api";
import { API_HOST } from "../constants";
import { Link } from "preact-router/match";

export default class LeftNav extends Component {
  state = { navs: null };
  componentDidMount() {
    this.api = new API(API_HOST);
    console.log("project");
    this.api.get("/navs").then(navs => this.setState({ navs }));
  }

  render() {
    let { navs } = this.state;
    return (
      <div className="bg-white rounded h-full p-2 navigation">
        <h3 className="mt-2 text-gray-600 py-2">Navigation</h3>
        {(navs || []).map(form => {
          if (!form.hide_nav) {
            return (
              <Link
                href={"/" + form.key}
                activeClassName="bg-gray-200"
                className="my-1 py-2 px-4 hover:bg-gray-200 text-gray-800 text-lg cursor-pointer block"
              >
                <span className={"fa fa-" + form.icon + " mr-2"}></span>
                {form.label}
              </Link>
            );
          }
        })}
      </div>
    );
  }
}
