import { h, Component } from "preact";
import { Router, route } from "preact-router";
import Header from "./header";
import Footer from "./footer";
import Home from "../routes/home";
import LeftNav from "./nav";
import LoginState from "../lib/login_state";

export default class App extends Component {
  componentDidMount() {
    LoginState.listeners.push(state => {
      this.setState({ isLoggedIn: state });
    });
    let isLoggedIn = LoginState.get();
    this.setState({ isLoggedIn });
  }
  componentWillUnmount() {
    LoginState.listeners = [];
  }
  render() {
    return (
      <div id="app">
        <Header />
        <div className="h-screen flex items-center p-4">
          <div className="container mx-auto h-full py-24 flex">
            <div className="w-64 h-full p-4 float-left border-r border-gray-300">
              <LeftNav />
            </div>
            <div className="flex-1 h-full p-4 float-left">
              <div className="bg-white rounded h-full overflow-auto pr-4">
                <Router>
                  <Home path="/:path?" />
                </Router>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
