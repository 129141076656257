import { h, Component } from "preact";
import { Link } from "preact-router/match";
import LoginState from "../lib/login_state";

export default class Header extends Component {
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user });
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    LoginState.set(false);
  }
  render() {
    let { user } = this.state;
    return (
      <header className="border-b p-4 fixed w-full bg-white">
        <div className="container mx-auto flex justify-between items-center">
          <Link activeClassName="active" href="/">
            <h1>
              <img src="/assets/logo.png" className="h-16" />
            </h1>
          </Link>
          <h1 className="text-4xl font-hairline">Config manager</h1>
          <nav></nav>
        </div>
      </header>
    );
  }
}
